@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

$timing        : 265ms;
$iconColor     : #3e7ef491; // purple: 8800ff91 // blue: 424b6991
$accent        : #905ba1; // change thisfor top right gradient
$grape         : #7b00e0;
$orchid        : #ae31d9;

@mixin transformScale($size: 1) {
    transform: scale($size);
    -ms-transform: scale($size);
    -webkit-transform: scale($size);
}

// body {
//     background-color: rgba($iconColor, 0.05);
// }

.social-container {
    width: 400px;
    // margin: 40vh auto;
    text-align: center;
    margin-top: 0px;
}

.social-icons {
    padding: 0;
    list-style: none;
    margin: 1em;
  
    li {
        display: inline-block;
        margin: 0.15em;
        position: relative;
        font-size: 1.2em;
        margin: 0px 10px;
    }

    i {
        color: #fff;
        position: absolute;
        top: 16px;
        left: 17px;
        transition: all $timing ease-out;
    }

    a {
        display: inline-block;
      
        &:before {
            @include transformScale();
            content: " ";
            width: 50px;
            height: 50px;
            border-radius: 100%;
            display: block;
            background: linear-gradient(45deg, $iconColor, $accent);
            transition: all $timing ease-out;
            // opacity: 0;
        }
        
        &:hover:before {
            transform: scale(0);
            transition: all $timing ease-in;
        }
        
        &:hover i {
            @include transformScale(2.2);
            color: $iconColor;
            background: -webkit-linear-gradient(45deg, $iconColor, $accent);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: all $timing ease-in;
        }
    }
  
    a.social-square {
      
        &:before {
            background: linear-gradient(45deg, $grape, $orchid);
            border-radius: 10%;
        }
      
        &:hover:before {
            transform: rotate(-180deg);
            -ms-transform: rotate(-180deg);
            -webkit-transform: scale(-180deg);
            border-radius: 100%;
        }
        
        &:hover i {
            @include transformScale(1.6);
            color: #fff;
            transform: scale(1.6);
            -webkit-text-fill-color: #fff;
        }
    }
}