.experience-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px; 
    padding: 40px;
    margin: 40px auto;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    width: 80%;
    max-width: 1000px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.experience-card {
    background: #17171b;
    align-self: center;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-center;
    transition: transform 0.3s ease;
    min-width: 300px;
    max-width: 100%;
}

.experience-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}  

.experience-title {
    font-size: 20px;
    color: #fff;
    margin-bottom: 15px;
}

.experience-description {
    color: #bbb;
    margin-bottom: 15px;
}

.experience-year {
    font-size: 14px;
    font-style: italic;
    color: #999;
}

.experience-description {
    font-size: 16px;
}

.section-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
    margin-bottom: 30px;
    font-size: 2.25em;
    color: #ffffff;
    background: -webkit-linear-gradient(left, #7f8287, #5f6877);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: float 6s ease-in-out infinite;
}

.path-section {
    text-align: center;
    padding: 20px;
    color: #fff;
}

.section-title::after {
    content: '';
    display: block;
    width: 100%;
    padding-top: 5px;
    border-bottom: 3px solid #5f6877;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.linkedin-card {
    background: #0077b5;
    color: #fff;
    text-align: center;
}

.linkedin-card:hover {
    background: #005582;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.path-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 2s ease-out, transform 2s ease-out;
}

.path-section.is-visible {
    opacity: 1;
    transform: none;
}
