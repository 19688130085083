.App {
    width:100%;
    overflow-x:hidden;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  text-align: center;
  color: #b7b8d2;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 1.5;
}

.social-container {
    padding-top: 25px;
}

.headline {
    font-weight: bold;
    font-size: 25px;
}

.App-header, .gallery-container {
    font-family: 'Comfortaa', sans-serif;
    color: #8d89a0;
}

.main-header {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 48px;
    font-weight: lighter;
    letter-spacing: 10px;
}

.intro {
    max-width: 600px;
    font-size: 18px;
    line-height: 1.75;
}

.header-pic {
    width: 200px;
    border-radius: 50%;
    background-color: rgba(168, 140, 198, 0.05);
}

.gallery-container {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 15vh;
}

.gallery {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gallery-list {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
    width: 100%;
}

.gallery-list li {
    display: inline;
    flex-wrap: wrap;
    position: relative;
    margin: 20px;
}

.gallery-list a {
    color: inherit;
}

.circle {
    height: 100px;
    width: 100px;
    background-color: rgba(52, 43, 62, 0.6);
    border-radius: 50%;
    text-align: center;
    margin: 20px;
    padding: 20px;
}

.gallery-text {
    line-height: 25px;
    max-width: 100px;
    margin-left: 42px;
    margin-top: 55px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    color: #FAF9F6;
    text-shadow: 1px 1px 1px black;
    font-weight: 650;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.25s;
    transition-timing-function: ease-in-out;
    
    
}

.overlay {
    transition: opacity 0.25s;
    transition-timing-function: ease-in-out;
    position: absolute;
    background-color: #464d68;
    opacity: .2;
    top: 20px;
    left: 20px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
}

a:hover .gallery-text {
    opacity: 1;
    transition: opacity 0.5s;
    transition-delay: 0.25s;
    transition-timing-function: ease-in-out;
}

.main-container img {
    opacity: 0.8;
    transition: opacity 0.5s;
    transition-delay: 0.25s;
    transition-timing-function: ease-in-out;
}

.main-container a:hover img {
    opacity: 0;
    transition: opacity 0.25s;
    transition-timing-function: ease-in-out;
}

a:hover .overlay {
    opacity: 0;
    transition: opacity 0.5s;
    transition-delay: 0;
    transition-timing-function: ease-in-out;
}

.underline {
    position: relative;
    width: 250px;
    height: 3px;
    background-color: #414466;
    border-radius: 10px;
}

@media only screen and (max-width: 400px) {
    .main-header {
        font-size: 36px;
        font-weight: bold;
    }
    .header-pic {
        width: 190px;
    }
}

.bottom-circle {
    align-items: center;
    width: 100%;
    height: 50%;
    border-radius: 50%;
    background-color: #D8D5F5;
  }