@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

.contributions-section {
  text-align: center;
  padding: 20px;
  color: #fff;
  padding-top: 50vh;
}

.contributions-container {
  display: flex;
  max-width: 1400px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 40px;
  margin: 40px auto;
}

.contribution-card {  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(145deg, rgba(51, 55, 84, 0.7), rgba(28, 28, 43, 0.7));
  color: #ffffff;
  width: 300px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  position: relative;
}

.contribution-title, .contribution-description, .contribution-link {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.contribution-link {
  color: #c9d1d9;
  text-decoration: none;
  font-size: 0.9em;
  display: block;
  transition: color 0.2s;
  word-break: break-all;
}

.contribution-link:hover {
  color: #a8bbd9;
  text-decoration: underline;
}

.contribution-card:hover {
  transform: translateY(-5px);
}

.contribution-info {
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contribution-card:hover .contribution-overlay {
  opacity: 1;
}

.contribution-image-container {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.contribution-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.contribution-link-container {
  padding: 8px;
  margin-top: auto;
  border-top: 2px solid #e1e4e8;
}

.view-contribution {
  color: inherit;
  text-decoration: none;
}
.contributions-section h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
  margin-bottom: 30px;
  font-size: 2.25em;
  background: -webkit-linear-gradient(left, #8d89a0, #78909c);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: float 6s ease-in-out infinite;
}

.contributions-section h2::after {
  content: '';
  display: block;
  width: 100%;
  padding-top: 5px;
  border-bottom: 3px solid #8d89a0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.contribution-image-container i {
  font-size: 100px;
  color: rgba(247, 152, 29, 0.85);
}

.contributions-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 2s ease-out, transform 2s ease-out;
}

.contributions-section.is-visible {
  opacity: 1;
  transform: none;
}